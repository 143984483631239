<template>
  <div class="pb-5 mb-5">
    <div>
      <b-row class="align-items-center">
        <b-col>
          <h2>Board stats</h2>
        </b-col>
        <b-col sm="9">
          <b-row>
            <b-col
              v-for="field in boardFilters"
              :key="`columns-filter-${field.key}`"
              class="px-50"
              :sm="12 / Math.ceil(boardFilters.length)"
            >
              <field
                class="ml-sm-1 w-100"
                :field="field"
                :entity="entity"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div v-if="isBoardStatsLoading" class="d-flex align-items-center justify-content-center p-5">
        <b-spinner />
      </div>
      <div v-else-if="boardStats.length === 0 || !canGetBoardData">
        <no-data
          title="Please change filters the to display data"
          description="Data may not be visible because you didn't select any filter"
        />
      </div>
      <b-row v-else>
        <b-col
          v-for="stat in boardStats"
          :key="`stat-item-board-${stat.board_name}-${stat.board_id}`"
          sm="12"
          md="6"
          lg="4"
        >
          <board-stat-item :stat="stat" />
        </b-col>
      </b-row>
    </div>
    <div class="mt-5">
      <b-row class="align-items-center">
        <b-col>
          <h2>Column stats</h2>
        </b-col>
        <b-col sm="4">
          <b-row>
            <b-col
              v-for="field in columnsFilters"
              :key="`columns-filter-${field.key}`"
              class="px-50"
            >
              <field
                class="ml-sm-1 w-100"
                :field="field"
                :entity="entity"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div v-if="isColumnsStatsLoading" class="d-flex align-items-center justify-content-center p-5">
        <b-spinner />
      </div>
      <div v-else-if="columnsStats.length === 0 || !canGetColumnsData">
        <no-data
          title="Please change filters the to display data"
          description="Data may not be visible because you didn't select any filter"
        />
      </div>
      <b-row v-else>
        <b-col
          v-for="stat in columnsStats"
          :key="`stat-item-column-${stat.column_name}-${stat.column_id}`"
          sm="12"
          md="6"
          lg="4"
        >
          <board-stat-item :stat="stat" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import NoData from '@/views/app/CustomComponents/NoData/NoData.vue'
import Field from '../Generic/Field.vue'
import { datePickerOptions } from './data'
import BoardStatItem from './widgets/BoardStatItem.vue'

const columnsFilters = [
  {
    key: 'board_id',
    type: 'list',
    list: 'board',
    listLabel: 'board_name',
    required: false,
  },
]

const boardFilters = [

  {
    key: 'customergroup_id',
    type: 'list',
    list: 'customergroup',
    listLabel: 'customergroup_name',
    required: false,
  },
  {
    key: 'company_id',
    type: 'list',
    list: 'frontend_2_2_3_1',
    listLabel: 'company_name',
    filter_key: 'customergroup_id',
    required: false,
  },
  {
    key: 'pos_id',
    type: 'list',
    list: 'frontend_2_1_3_8',
    listLabel: 'pos_name',
    filter_key: 'company_id',
    required: false,
  },
]

export default {
  name: 'DashboardBoard',
  components: {
    BoardStatItem,
    Field,
    NoData,
  },
  data() {
    return {
      entity: {
        board_id: '',
        customergroup_id: '',
        company_id: '',
        pos_id: '',
      },
      date: '',
      datePickerOptions,
      columnsFilters,
      boardFilters,
      isBoardStatsLoading: false,
      isColumnsStatsLoading: false,
      boardStats: [],
      columnsStats: [],
    }
  },
  computed: {
    canGetBoardData() { return this.entity.customergroup_id || this.entity.company_id || this.entity.pos_id },
    canGetColumnsData() { return this.entity.board_id },
  },
  watch: {
    'entity.board_id': function (board_id) {
      if (!this.canGetColumnsData) return

      this.getColumnsStats(board_id)
    },
    'entity.company_id': function (company_id) {
      this.entity.pos_id = ''
      if (!this.canGetBoardData) return

      this.getBoardStats({
        company_id,
        pos_id: this.entity.pos_id,
        customergroup_id: this.entity.customergroup_id,
      })
    },
    'entity.pos_id': function (pos_id) {
      if (!this.canGetBoardData) return

      this.getBoardStats({
        pos_id,
        company_id: this.entity.company_id,
        customergroup_id: this.entity.customergroup_id,
      })
    },
    'entity.customergroup_id': function (customergroup_id) {
      this.entity.company_id = ''
      if (!this.canGetBoardData) return

      this.getBoardStats({
        customergroup_id,
        company_id: this.entity.company_id,
        pos_id: this.entity.pos_id,
      })
    },
  },
  methods: {
    async getBoardStats(payload) {
      if (!payload?.customergroup_id) {
        this.$errorToast('Please select a customer group at least')
        return
      }

      const url = '/tickets/statistics/timing/board'
      try {
        this.isBoardStatsLoading = true
        const params = {}
        Object.keys(payload).forEach(key => {
          if (payload[key] || payload[key] === 0) {
            params[key] = payload[key]
          }
        })
        const { data } = await this.$http.get(url, {
          params,
        })
        this.boardStats = data
      } catch (e) {
        this.$errorToast('Error while loading board stats data')
      } finally {
        this.isBoardStatsLoading = false
      }
    },
    async getColumnsStats(board_id) {
      if (!board_id) {
        this.$errorToast('Please select a board')
        return
      }

      const url = `/tickets/statistics/timing/board/${board_id}`
      try {
        this.isColumnsStatsLoading = true
        const { data } = await this.$http.get(url)
        this.columnsStats = data
      } catch (e) {
        this.$errorToast(e.message ?? 'Error while loading board stats data')
      } finally {
        this.isColumnsStatsLoading = false
      }
    },
  },
}
</script>
